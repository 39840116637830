import PropTypes from 'prop-types';
import LoopingTextBannerDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/LoopingTextBannerDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const LoopingTextBannerModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    return (
        <section
            className={css({
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                borderRadius: 'var(--border-radius-module)',
                overflow: 'hidden',
            })}
        >
            <LoopingTextBannerDataTransformerBlock {...rest} />
        </section>
    );
};

LoopingTextBannerModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default LoopingTextBannerModule;
